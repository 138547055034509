<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách hỗ trợ</h3>
      </div>
      <div class="row">
        <ModalCheckboxFilter :filterOptions="filterOptions" class="mr-3 mt-5" @checkboxSuccess="checkboxSuccess">
        </ModalCheckboxFilter>
        <ModalCreateTicket v-if="currentUser.user.permission.includes(permission.THEM_MOI_HO_TRO)" class="mr-3 mt-5"
          :sources="sources" :departments="departments" :categories="categories" :objectFeedback="objectFeedback"
          @create-success="getList"></ModalCreateTicket>
        <div v-if="currentUser.user.permission.includes(permission.EXPORT_TICKET_TRUNG_TAM_HO_TRO)">
          <button class="btn btn-success font-weight-bold mt-5 btn-sm mr-2" @click="exportExcel"
            :class="{ 'spinner spinner-white spinner-right': is_export }">
            <i class="la la-file-excel"></i>Xuất excel
          </button>
          <button class="btn btn-success font-weight-bold mt-5 btn-sm" @click="exportExcelV2"
                  :class="{ 'spinner spinner-white spinner-right': is_export }">
            <i class="la la-file-excel"></i>Xuất excel v2
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Mã ticket</label>
            <el-input placeholder="Mã ticket" v-model="query.ticket_id"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Mã học viên, tên, SĐT, email</label>
            <el-input placeholder="Mã học viên, tên, SĐT, email" v-model="query.keyword"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Tên người phản hồi</label>
            <el-input placeholder="Nhập tên người phản hồi" v-model="query.nguoi_phan_hoi"></el-input>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Nguồn phản hồi')">
          <div class="form-group">
            <label>Nguồn phản hồi</label>
            <el-select v-model="query.source" filterable class="w-100" placeholder="Nguồn phản hồi" clearable multiple
              collapse-tags>
              <el-option v-for="item in sources" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group w-100">
              <label>Độ ưu tiên</label>
              <el-select v-model="query.priorities" filterable class="w-100" placeholder="Độ ưu tiên" clearable>
                <el-option label="Tiêu chuẩn" :value="1"> </el-option>
                <el-option label="Ưu tiên" :value="2"> </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group w-100">
              <label>Trạng thái bình luận</label>
              <el-select v-model="query.status_feedback" filterable class="w-100" placeholder="Trạng thái bình luận" collapse-tags clearable>
                <el-option v-for="item in status_feedbackDefine" :key="item.id" :label="item.name" :value="item.id">
                  <span style="float: left">{{ item?.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>  
        <div class="col-md-3">
          <div class="form-group">
            <label>Trạng thái</label>
            <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái" clearable multiple
              collapse-tags>
              <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="col-md-3" v-if="checkShowFilter('Đối tượng phản hồi')">
          <div class="form-group">
            <label>Đối tượng phản hồi</label>
            <el-select v-model="query.doi_tuong_phan_hoi" filterable class="w-100" placeholder="Đối tượng phản hồi"
              @input="handleCenter" multiple collapse-tags clearable>
              <el-option v-for="item in objectFeedback" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Vấn đề phòng ban')">
          <div class="form-group">
            <label>Vấn đề phòng ban</label>
            <el-select v-model="query.van_de_phong_ban" filterable class="w-100" @change="handleDepartmentFilter"
              placeholder="Vấn đề phòng ban" clearable multiple collapse-tags>
              <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Danh mục')">
          <div class="form-group">
            <label>Danh mục</label>
            <el-select v-model="query.category_ticket_id" filterable class="w-100" placeholder="Danh mục"
              @input="handleCategory" clearable multiple collapse-tags>
              <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Bên xử lý')">
          <div class="form-group">
            <label>Bên xử lý</label>
            <el-select v-model="query.phong_ban_xu_ly" filterable class="w-100" placeholder="Bên xử lý"
              @input="handleCourse" clearable multiple collapse-tags>
              <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Đánh giá ticket')">
          <div class="form-group">
            <label>Đánh giá ticket</label>
            <el-select v-model="query.feedback_ticket_star" filterable class="w-100" placeholder="Đánh giá ticket"
              clearable multiple collapse-tags>
              <el-option v-for="item in feedback_stars" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Thời gian tạo')">
          <div class="form-group">
            <label>Thời gian tạo</label>
            <date-picker placeholder="Thời gian tạo" range v-model="query.created_at" format="DD-MM-YYYY"
              valueType="YYYY-MM-DD"></date-picker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Nội dung</label>
            <el-input placeholder="Nội dung" v-model="query.content"></el-input>
          </div>
        </div>
        <div class="col-md-3" v-if="checkShowFilter('Người tạo')">
          <div class="form-group">
            <label>Người tạo</label>
            <el-select v-model="query.created_user" filterable class="w-100" placeholder="Người tạo" clearable multiple
              collapse-tags>
              <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item?.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>  
        <div  class="col-md-3" style="margin-top: 27px;"
          v-if="checkedFilters.length">
          <div class="d-flex justify-content-between align-items-center">
            <button type="button" class="btn btn-primary font-weight-bold" :disabled="is_disable_search"
              :class="{ 'spinner spinner-white spinner-left': is_disable_search }" @click="search">
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th scope="col">STT</th>
                <th scope="col">Mã ticket</th>
                <th scope="col">Người phản hồi</th>
                <th scope="col">Nguồn</th>
                <th scope="col" class="min-w-150px">Danh mục</th>
                <th scope="col">Trạng thái ticket</th>
                <th scope="col">Trạng thái bình luận</th>
                <th scope="col" class="min-w-130px">Độ ưu tiên</th>
                <th scope="col" class="min-w-130px">Người tạo</th>
                <th scope="col" class="min-w-130px">Bên xử lý</th>
                <th scope="col" class="min-w-150px">Đánh giá Ticket</th>
                <th scope="col" class="min-w-150px">Thời gian tạo</th>
                <th scope="col" class="min-w-100px">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tickets.data" :key="index">
                <td>{{ getIndexPaginate(tickets.current_page, index, 20) }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.nguoi_phan_hoi }}</td>
                <td>{{ item.source ? sourcesObject[item.source] : '' }}</td>
                <td>{{ item.category_ticket ? item.category_ticket && item.category_ticket?.name : category_chamsoc_define[item.category_chamsoc]}}</td>
                <td>
                  <span :class="statusDefineCss[item.status]">{{ statusDefine[item.status] }}</span>
                </td>
                  <td>
                  <span :class="statusFeedback[item.status_comment]">{{ statusFeedbackDefine[item.status_comment] }}</span>
                </td>
                <td>
                  <span v-if="item && item.priority >= 1"
                    :class="priorityDefineCss[item.priority]">{{
                      priorityDefine[item.priority]
                    }}
                  </span>
                </td>
                <td>
                  {{ item.created_user ? item?.created_user?.name : '' }}
                </td>
                <td>{{ item.phong_ban_xu_ly_rel ? item.phong_ban_xu_ly_rel.name : '' }}</td>
                <td>
                  <span class="text-primary" v-if="item.feedback_ticket_star == null">Chưa nhập liệu</span>
                  <span class="text-danger" v-else-if="item.feedback_ticket_star == 0">Không đánh giá</span>
                  <el-rate v-else v-model="item.feedback_ticket_star" disabled text-color="#ff9900"
                    score-template="{value} points"></el-rate>
                </td>
                  <td>{{ item.created_at | formatDateTimeVietnam }}</td>
                <td>
                  <router-link :to="{ name: 'crm-ticket-show', params: { id: item.id } }" title="Xem"
                    class="btn btn-icon  btn-outline-info mr-2 btn-sm"
                    v-if="currentUser.user.permission.includes(permission.XEM_CHI_TIET_HO_TRO)">
                    <i class="fas far fa-eye fa-sm"></i>
                  </router-link>
                  <a v-show="item.status != 2 && item.status != 3" title="Xóa" @click="deleteTicket(item.id)"
                  href="javascript:" class="btn btn-icon btn-outline-danger btn-sm"
                  v-if="currentUser.user.permission.includes(permission.XOA_HO_TRO)"><i
                  class="fas fa-trash fa-sm"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ModalCreateTicket from "./components/ModalCreateTicket";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_BRANCHES } from "@/core/services/store/center/branch.module";
import { GET_COURSES } from "@/core/services/store/course/course.module";
import { GET_CLASSES } from "@/core/services/store/course/classes.module";
import { GET_CENTERS } from "@/core/services/store/center/centers.module";
import {
  DELETE_TICKET, EXPORT_EXCEL_TICKET,
  GET_ALL_TICKET, GET_OBJECT_FEEDBACK,
  GET_SOURCE,
  GET_TICKET_CATEGORIES,
  EXPORT_EXCEL_TICKET_V2,
  GET_ALL_CREATED_USER_TICKET,
  REASON_ERROR,
  CATEGORY
} from "@/core/services/store/user/ticket.module";
import { GET_DEPARTMENT } from "@/core/services/store/user/department.module";
import ModalCheckboxFilter from "./components/ModalCheckboxFilter";
import { mapGetters } from "vuex";
import {exportExcel} from "@/core/filters";

export default {
  name: "CrmTicketIndex",
  components: {
    ModalCheckboxFilter,
    ModalCreateTicket,
    DatePicker
  },
  data() {
    return {
      category: CATEGORY,
      config: {
        showMode: 'none',
        top: 60,
        right: 200,
        arrowLeft: 155,
        arrowDisplay: 'top',
        fromNumbers: [
          { alias: 'Number-1', number: '+84899199586' },
          { alias: 'Number-2', number: '+2222' }
        ],
        askCallTypeWhenMakeCall: false,
        appendToElement: null,
        makeAndReceiveCallInNewPopupWindow: false
      },
      reasons: [],
      text_reason: '',
      callStringeeId: '',
      checkIncoming: false,
      checkChangeIcon: false,
      messageError: '',
      reasonError: REASON_ERROR,
      page: 1,
      last_page: 1,
      is_disable_search: false,
      is_export: false,
      tickets: [],
      users: [],
      query: {
        ticket_id: '',
        keyword: '',
        nguoi_phan_hoi: '',
        source: [],
        category_ticket_id: [],
        status: [],
        doi_tuong_phan_hoi: [],
        van_de_phong_ban: [],
        phong_ban_xu_ly: [],
        priorities: '',
        status_feedback: '',
        feedback_ticket_star: [],
        created_at: '',
        is_auto: [3],
        content: '',
        created_user: [],
        is_get_user_created: true
      },

      status_feedbackDefine: 
      [
        {id: 0, name: "Chưa bình luận"},
        {id: 1, name: "Có bình luận mới"},
        {id: 2, name: "Đã bình luận"},
      ],

      branches: [],
      centers: [],
      courses: [],
      classes: [],
      status: [
        {
          id: 1,
          name: "Chờ xử lý"
        },
        {
          id: 2,
          name: "Đã nhập giải pháp"
        },
        {
          id: 3,
          name: "Đã hoàn thành"
        }
      ],
      feedback_stars: [
        {
          id: null,
          name: "Chưa nhập liệu"
        },
        {
          id: 0,
          name: "Không đánh giá"
        },
        {
          id: 1,
          name: "1 sao"
        },
        {
          id: 2,
          name: "2 sao"
        },
        {
          id: 3,
          name: "3 sao"
        },
        {
          id: 4,
          name: "4 sao"
        },
        {
          id: 5,
          name: "5 sao"
        }
      ],
      priorityDefine: {
        1: "Tiêu chuẩn",
        2: "Ưu tiên",
      },
      priorityDefineCss: {
        1: "",
        2: "text-danger",
      },
      statusFeedbackDefine: {
        0: "Chưa bình luận",
        1: "Có bình luận mới",
        2: "Đã bình luận",
      },
      statusFeedback: {
        0: "badge badge-info",
        1: "badge badge-danger",
        2: "badge badge-success",
      },
      statusDefine: {
        1: "Chờ xử lý",
        2: "Đã nhập giải pháp",
        3: "Đã hoàn thành",
      },
      statusDefineCss: {
        1: "badge badge-warning",
        2: "badge badge-danger",
        3: "badge badge-success",
      },
      statusDefineTextCss: {
        1: "badge badge-warning",
        2: "badge badge-danger",
        3: "badge badge-success",
      },
      show: {
        lesson_leave: false,
        feedback_star: false,
        feedback_comment: false,
        exam_type: false,
        exam_total_score: false
      },
      showFilter: {
        star: false,
        exam: false
      },
      sources: [],
      departments: [],
      categories: [],
      objectFeedback: [],
      sourcesObject: {},
      filterOptions: [
        'Nguồn phản hồi', 'Danh mục', 'Đối tượng phản hồi', 'Vấn đề phòng ban',
        'Bên xử lý', 'Đánh giá ticket', 'Thời gian tạo', 'Người tạo'
      ],
      checkedFilters: ['Mã ticket', 'Mã học viên,tên,SĐT,email', 'Người phản hồi', 'Trạng thái', 'Trạng thái bình luận'],
      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'Chua đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp',
        7: 'Nghỉ học 1 buổi',
        8: 'Nghỉ học 1 buổi',
        9: 'Hướng dẫn app cho phụ huynh',
        10: 'Kết quả BTVN không tốt',
        11: 'Kết quả BTVN không tốt'
      },
    }
  },
  created() {
    // if (this.currentUser.userTokenStringee.length) {
    //   this.config.showMode = 'full';
    // }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách hỗ trợ", route: 'crm-ticket-index' },
      { title: "Danh sách hỗ trợ" }
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    if ($("div").hasClass('hide')) {
      document.getElementById("buttonCall").classList.add('show');
      document.getElementById("buttonCall").classList.remove('hide');
    }
    this.pullParamsUrl();
    this.getCenters();
    this.getBranches();
    this.getList();
    this.getCourse();
    this.getClasses();
    this.getObjectFeedback();
    this.getSource();
    this.getDepartments();
    this.getTicketCategories();
  },
  methods: {
    exportExcel() {
      this.is_export = true;
      const HEADER_ROW_EXPORT_TICKETS_CSHV = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã ticket',
        },
        {
          key: 'source',
          value: 'Nguồn',
        },
        {
          key: 'student_edutalk',
          value: 'Gán ticket cho khách hàng',
        },
        {
          key: 'nguoi_phan_hoi',
          value: 'Người phản hồi',
        },
        {
          key: 'category_ticket_name',
          value: 'Danh mục',
        },
        {
          key: 'status',
          value: 'Trạng thái ticket',
        },
        {
          key: 'priority',
          value: 'Độ ưu tiên',
        },
        {
          key: 'created_user',
          value: 'Người tạo',
        },
        {
          key: 'van_de_phong_ban',
          value: 'Vấn đề phòng ban',
        },
        {
          key: 'phong_ban_xu_ly',
          value: 'Bên xử lý',
        },
        {
          key: 'feedback_ticket_star',
          value: 'Đánh giá ticket',
        },
        {
          key: 'created_at',
          value: 'Thời gian tạo',
        },
        {
          key: 'send_message_at',
          value: 'Thời gian khách hàng nhắn',
        },
        {
          key: 'reply_message_at',
          value: 'Thời gian trả lời',
        },
        {
          key: 'error_solution',
          value: 'Lỗi nhập giải pháp',
        },
        {
          key: 'error_done',
          value: 'Lỗi hoàn thành',
        },
        {
          key: 'error_no_review',
          value: 'Lỗi nhập đánh giá',
        },
        {
          key: 'time_handle_solution',
          value: 'Thời gian xử lý',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TICKET, this.query).then((data) => {
        this.is_export = false
        if (data.data.error) {
          this.$bvToast.toast(data.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_TICKETS_CSHV, data.data, "Danh sách hỗ trợ.xlsx", true)
          this.$bvToast.toast(data.data.message, {
            title: 'Export excel',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    exportExcelV2() {
      this.is_export = true;
      const HEADER_ROW_EXPORT_TICKETS_CSHV = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'ticket_id',
          value: 'Mã ticket',
        },
        {
          key: 'source',
          value: 'Nguồn',
        },
        {
          key: 'student_edutalk',
          value: 'Gán ticket cho khách hàng',
        },
        {
          key: 'nguoi_phan_hoi',
          value: 'Người phản hồi',
        },
        {
          key: 'category_ticket_name',
          value: 'Danh mục',
        },
        {
          key: 'status',
          value: 'Trạng thái ticket',
        },
        {
          key: 'priority_text',
          value: 'Độ ưu tiên',
        },
        {
          key: 'created_user',
          value: 'Người tạo',
        },
        {
          key: 'van_de_phong_ban',
          value: 'Vấn đề phòng ban',
        },
        {
          key: 'phong_ban_xu_ly',
          value: 'Bên xử lý',
        },
        {
          key: 'feedback_ticket_star',
          value: 'Đánh giá ticket',
        },
        {
          key: 'created_at',
          value: 'Thời gian tạo',
        },
        {
          key: 'send_message_at',
          value: 'Thời gian khách hàng nhắn',
        },
        {
          key: 'reply_message_at',
          value: 'Thời gian trả lời',
        },
        {
          key: 'error_solution',
          value: 'Lỗi nhập giải pháp',
        },
        {
          key: 'error_done',
          value: 'Lỗi hoàn thành',
        },
        {
          key: 'error_no_review',
          value: 'Lỗi nhập đánh giá',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TICKET_V2, this.query).then((data) => {
        this.is_export = false
        if (data.data.error) {
          this.$bvToast.toast(data.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_TICKETS_CSHV, data.data, "Danh sách hỗ trợ.xlsx", true)
          this.$bvToast.toast(data.data.message, {
            title: 'Export excel',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      this.query.ticket_id = this.$route.query.ticket_id ? parseInt(this.$route.query.ticket_id) : ''                                                                                                         
      this.query.keyword = this.$route.query.keyword
      this.query.nguoi_phan_hoi = this.$route.query.nguoi_phan_hoi ? this.$route.query.nguoi_phan_hoi: ''
      this.query.source = this.$route.query.source ? [this.$route.query.source] : ''
      this.query.category_ticket_id = this.$route.query.category_ticket_id ? [parseInt(this.$route.query.category_ticket_id)] : ''
      this.query.status = this.$route.query.status ? [parseInt(this.$route.query.status)] : ''
      this.query.status_feedback = this.$route.query.status_feedback ? parseInt(this.$route.query.status_feedback) : ''
      this.query.doi_tuong_phan_hoi = this.$route.query.doi_tuong_phan_hoi ? [this.$route.query.doi_tuong_phan_hoi] : ''
      this.query.van_de_phong_ban = this.$route.query.van_de_phong_ban ? [parseInt(this.$route.query.van_de_phong_ban)] : ''
      this.query.phong_ban_xu_ly = this.$route.query.phong_ban_xu_ly ? [parseInt(this.$route.query.phong_ban_xu_ly)] : ''
      this.query.priorities = this.$route.query.priorities ? parseInt(this.$route.query.priorities) : ''
      this.query.feedback_ticket_star = this.$route.query.feedback_ticket_star ? [parseInt(this.$route.query.feedback_ticket_star)] : ''
      this.query.created_at = this.$route.query.created_at
      this.query.content = this.$route.query.content
    },
    checkShowFilter(name) {
      return this.checkedFilters.includes(name);
    },
    getObjectFeedback() {
      this.objectFeedback = [];
      this.$store.dispatch(GET_OBJECT_FEEDBACK).then((data) => {
        for (const key in data.data) {
          this.objectFeedback.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
    getSource() {
      this.sources = [];
      this.$store.dispatch(GET_SOURCE).then((data) => {
        for (const key in data.data) {
          this.sources.push({
            id: key,
            name: data.data[key]
          })
          this.sourceConvertToObject();
        }
      })
    },
    sourceConvertToObject() {
      for (let i = 0; i < this.sources.length; i++) {
        let item = this.sources[i];
        this.sourcesObject[item.id] = item.name;
      }
    },
    getDepartments() {
      this.$store.dispatch(GET_DEPARTMENT, {
        all: true,
        status: 1
      }).then((data) => {
        this.departments = data.data;
      })
    },

    handleDepartmentFilter() {
      this.categories = [];
      this.query.category_ticket_id = [];
      this.getTicketCategories();
    },
    getTicketCategories() {
      this.$store.dispatch(GET_TICKET_CATEGORIES, {
        limit: 1000,
        van_de_phong_ban_ids: this.query.van_de_phong_ban
      }).then((data) => {
        this.categories = data.data;
      })
    },
    handleCenter() {
      this.query.branch_ids = [];
      this.branches = [];
      this.getBranches();
    },
    handleCourse() {
      this.query.class_ids = [];
      this.classes = [];
      this.getClasses();
    },
    async handleCategory() {
      await this.resetShow();
      this.showFilter.star = false;
      this.showFilter.exam = false;
      switch (this.query.category) {
        //Nghỉ học 2 buổi
        case 1:
          this.show.lesson_leave = true;
          break;
        //Điểm kiểm tra thấp
        case 2:
          this.showFilter.exam = true;
          this.show.exam_type = true;
          this.show.exam_total_score = true;
          break;
        //Feedback thấp
        case 6:
          this.showFilter.star = true;
          this.show.feedback_star = true;
          this.show.feedback_comment = true;
          break;
      }
    },
    resetShow() {
      this.show.lesson_leave = false;
      this.show.exam_type = false;
      this.show.exam_total_score = false;
      this.show.feedback_star = false;
      this.show.feedback_comment = false;
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        status: 1,
        limit: 100000
      }).then((data) => {
        if (!data.error) {
          this.centers = data.data.data;
        }
      })
    },

    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        status: 1,
        limit: 100000,
        center_ids: this.query.center_ids
      }).then((data) => {
        if (!data.error) {
          this.branches = data.data.data;
        }
      })
    },

    getCourse() {
      this.$store.dispatch(GET_COURSES, {
        status: 1,
        per_page: 100000
      }).then((data) => {
        if (!data.error) {
          this.courses = data.data.data;
        }
      })
    },

    getClasses() {
      this.$store.dispatch(GET_CLASSES, {
        status: 1,
        limit: 100000,
        course_id: this.query.course_ids
      }).then((data) => {
        if (!data.error) {
          this.classes = data.data.data;
        }
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.pushParamsUrl();
      this.getList()
    },
    getList() {
      this.is_disable_search = true;
      this.$store.dispatch(GET_ALL_TICKET,
        {
          page: this.page,
          ...this.query
        }).then((data) => {
          this.tickets = data.data;
          this.last_page = data.data.last_page;
          this.is_disable_search = false;
        }).catch((error) => {
          console.log(error);
        });
      this.$store.dispatch(GET_ALL_CREATED_USER_TICKET,
        {
          ...this.query
        }).then((data) => {
          this.users = data.data;
        }).catch((error) => {
          console.log(error);
        });
    },
    search() {
      this.page = 1;
      this.pushParamsUrl();
      this.getList();
    },
    pushParamsUrl() {
      this.$router.push({
        path: '', query: {
          page: this.page,
          ...this.query
        }
      })
    },
    checkboxSuccess($event) {
      this.checkedFilters = $event;
    },

    deleteTicket(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_TICKET, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.bg-violet {
  background: #5E00B6;
}
</style>
